'use strict';

import $ from 'jquery';

$(() => {

  // ---------------------------------------------------- //
  // TOGGLE PASSWORD VISIBILITY
  // ---------------------------------------------------- //
  const togglePasswordVisibility = function($input, $button) {
    const type = $input.attr('type');
    if(type === 'password') {
      $input.attr('type', 'text');
      $button.addClass('ui-active');
    } else {
      $input.attr('type', 'password');
      $button.removeClass('ui-active');
    }
    $input.focus();
  };

  // ---------------------------------------------------- //
  // MANAGE BUTTON VISIBILITY
  // ---------------------------------------------------- //
  const manageButtonVisibility = function($input, $button) {
    $input.val() === '' ?
      $button.removeClass('ui-visible') :
      $button.addClass('ui-visible');
  };

  // ---------------------------------------------------- //
  // INIT
  // ---------------------------------------------------- //

  // Cache selectors
  const container = '.ui-passwordToggleVisibility',
        input = '.ui-passwordToggleVisibilityInput',
        button = '.ui-passwordToggleVisibilityButton';

  // Manage handlers on each element
  $(container).each( function() {

    // Cache elements
    const $container = $(this),
          $button = $container.find(button),
          $input = $container.find(input);

    // Toggle password visibility
    $button.on('click', e => {
      e.preventDefault();
      togglePasswordVisibility($input, $button);
    });

    // Manage button visibility
    $input.on('input', e => {
      manageButtonVisibility($input, $button);
    });

  });

});