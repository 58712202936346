'use strict';

// import $ from 'jquery';
import _ from './lodash';

const defaultTargetOrigin = '*'; // TODO be more specific

export default (type, data = {}, targetOrigin = defaultTargetOrigin) => {
  if (window && window.parent && window.parent.postMessage) {
    window.parent.postMessage(_.merge({ type }, data), targetOrigin);
    // $('body').prepend('postmessage' + type + '<br/>');
  }
};
