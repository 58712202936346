'use strict';

/* eslint-env browser */

import $ from 'jquery';
import postMessage from './libs/post-message';
import './libs/ui-loading';
import './libs/ui-passwordToggleVisibility';
import { isPowerPointAddin, pptAddinClickOnDocument, pptAddinKeyboardShortcuts } from './libs/powerpoint';

// When use is not authenticated, reset its browser session
// This handles the case use has disconnected and then reconnects WITHOUT closing tab or window
window.sessionStorage.clear();

// Handle "back" link
$('.js-back').on('click', e => {
   e.preventDefault();
   history.back();
});

// BROADCASTING FROM POWERPOINT
const ppt = isPowerPointAddin();
if(ppt) {
  postMessage('loginForm');
  pptAddinClickOnDocument();
  pptAddinKeyboardShortcuts(true);
}

// Auto-refresh login page after 10 minutes of inactify (no change on form)
// usage example: refresh message if session went off (see #2034)
let lastChange = Date.now();
$('input').on('change', () => lastChange = Date.now());
setInterval(() => {
  if (Date.now() - lastChange >= 10 * 60 * 1000) {
    document.location.reload();
  }
}, 1 * 60 * 1000);

// We insert hash value to pass to server on login to keep it on redirection #2515
$('.js-urlHash').val(($(document.location).prop('hash') || '').substr(1));
