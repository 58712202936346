/*
Custom "build" of lodash for frontend
-> Beware of frontend imports from backend using lodash functions (poll-vote-index.js/computeVoteIndex())
*/
'use strict';

import assignWith from 'lodash/assignWith';
import cloneDeep from 'lodash/cloneDeep';
import countBy from 'lodash/countBy';
import debounce from 'lodash/debounce';
import differenceWith from 'lodash/differenceWith';
// import each from 'lodash/each'; // _.each() is _.forEach() alias
import escape from 'lodash/escape';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import invokeMap from 'lodash/invokeMap';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import join from 'lodash/join';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import max from 'lodash/max';
import merge from 'lodash/merge';
import min from 'lodash/min';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pull from 'lodash/pull';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';
import reverse from 'lodash/reverse';
import size from 'lodash/size';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import split from 'lodash/split';
import loTemplate from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import throttle from 'lodash/throttle';
import uniq from 'lodash/uniq';
import zipObject from 'lodash/zipObject';

const template = (str, opts = {}) => loTemplate(str, {...opts, ...{ 
  evaluate:    /<\$([\s\S]+?)\$>/g,
  interpolate: /<\$=([\s\S]+?)\$>/g,
  escape:      /<\$-([\s\S]+?)\$>/g,
  imports:     { _ }
}});

const _ = { assignWith, cloneDeep, countBy, debounce, differenceWith, each: forEach,
            escape, filter, find, findIndex, forEach, get, includes, indexOf, invokeMap,
            isEqual, isArray, isObject, join, map, mapValues, max, merge, min, noop,
            omit, pick, pull, range, reduce, remove, reverse, size, some, sortBy, split,
            template, templateSettings, throttle, uniq, zipObject
};

export default _;
