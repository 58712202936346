'use strict';

import $ from 'jquery';
import _ from './lodash';
import postMessage from './post-message';

export const isPowerPointAddin = () => {
   return window.location.href.match(/[?&]type=ppt(#|&|$)/) || (window.parent && window.parent.Office);
};

export const setBroadcastorPowerPointFlag = bool =>
  $(document.body).attr('data-broadcastor-ppt', bool ? '1' : '0');

// document click to be handled by parent container
export const pptAddinClickOnDocument = () => {
  $(document).on('click', () => {
    postMessage('click');
  });
  $(document).on('click', '.js-noPropagation', function(e) {
    e.stopPropagation();
  });
};

// Manage ppt commands
export const pptAddinCmds = () => {
  $(document).on('click', '.js-pptCmd', function(e) {
    e.stopPropagation();
    const $this = $(this),
          cmd = $this.attr('data-cmd');
    // console.log(cmd);
    postMessage('pptCmd', {cmd});
    return false;
  });
};

// Manage ppt keyboard shortcuts
export const pptAddinKeyboardShortcuts = (filterInputKeys = false) => {

  // Keyboard shortcuts handled in parent frame, see home.js
  // https://keycode.info/
  // When iframe has focus, transmit certain keyboard entries
  // within a set of delegate keys, to be handeled by parent container
  const delegateKeys = filterInputKeys ? [34,40,33,38] : [78,13,34,39,40,32,80,33,37,38,8];

  // parent container will filter according to ppt mode (read | show)
  $(document).keyup(function(e){
    const k = _.find(delegateKeys, v => e.keyCode == v);
    if (k) postMessage('keyboardEntries',{code:e.keyCode});
  });
};